* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

/* for forms */
input {
  display: block;
  width: 20rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 2rem;
}
button {
  border: none;
  padding: 1rem;
  width: 21.2rem;
  font-size: 1.2em;
  border-radius: 0.2rem;
  cursor: pointer;
}
button:hover {
  background-color: #c5c5c5;
}
.message {
  font-size: 1.2em;
  text-align: center;
  color: #36a936;
}

.CompoundInterest {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}